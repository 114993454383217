import * as React from "react";
import "../styles/main.css";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import DefaultPage from "../components/DefaultPage";

let system_name = "about";

const AboutPage = ({ data }) => {
  console.log("CONTACT page her er data ", data);

  const page = data.allMarkdownRemark.nodes[0];
  console.log("CONTACT page her er page ", page);

  let heading = page.frontmatter.heading;
  let html = page.html;

  return (
    <Layout>
      <DefaultPage heading={heading} html={html} />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query getAboutPageQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { system_name: { eq: "about" } }
        fileAbsolutePath: { regex: "/.*_pages.*/" }
      }
    ) {
      nodes {
        frontmatter {
          system_name
          heading
        }
        html
      }
    }
  }
`;
