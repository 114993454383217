import * as React from "react";

const DefaultPage = ({ heading, texts, html }) => (
  <section>
    <h1>{heading}</h1>

    {texts
      ? texts.map((text, index) => (
          <p key={index} style={{ textAlign: "center", padding: "10px" }}>
            {text}
          </p>
        ))
      : null}

    <div dangerouslySetInnerHTML={{ __html: html }} />
  </section>
);

export default DefaultPage;
